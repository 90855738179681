import React, {Component} from 'react';
import axios from 'axios';
import './css/main.css';

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: "",
            shortUrl: "",
            shortDone: false
        }
    }

    urlChange(event) {
        this.setState({
            url: event.target.value
        });
    }

    go(event) {
        event.preventDefault();
        let payload = {
            url: this.state.url
        };

        let headers = {
            "Content-Type": "application/json"
        };

        axios.post("https://hans-l.im/", JSON.stringify(payload), {headers: headers})
            .then((response) => {
                    this.setState({
                        shortUrl: response.data.short_url,
                        shortDone: true
                    });
                })
    }

    render() {
        return (
            <div className="container">
                <h1>Shorten URL</h1>
                <form onSubmit={this.go.bind(this)}>
                    <input type={"text"} className={"urlInput"} value={this.state.url} onChange={this.urlChange.bind(this)} />
                    <button type={"button"} className={"urlButton"} onClick={this.go.bind(this)}>GO!</button>
                </form>
                {this.state.shortDone &&
                    <div className={"shortBox"}>
                        <h3>{this.state.shortUrl}</h3>
                    </div>
                }
            </div>
        );
    }
}

export default App;
